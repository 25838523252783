.hover-blue-tint:hover, .link-like {
  color: #9e721d;
}

.active-blue-light:active, .link-like:active {
  color: #a7781f !important;
}

.link-like:hover {
  color: #966c1c;
}
