.auth-container {
  max-width: 500px !important;
  padding: 20px;
  margin: 50px auto;

  h1 {
    border-bottom: 2px solid #eee;
    padding-bottom: 20px;
    margin-bottom: 30px !important;
  }

  .form-control, .form-control:focus {
    border: none;
    outline: none !important;
    background-color: #f4f4f4;
    padding: 15px 20px !important;
    height: unset !important;
  }

  .btn {
    border: none;
    border-radius: 20px;
    padding: 8px 30px !important;
  }
}