.bc-modal-container {
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .bc-modal {
    width: 100%;
    max-width: 700px;
    max-height: 90vh;
    overflow: auto;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 1px 20px rgba(0,0,0,0.3);

    .bc-modal-title {
      padding: 20px;
      padding-bottom: 5px;
      font-size: 30px;
      font-weight: normal;
      color: #777;

      .bc-modal-close {
        float: right;
        font-size: 16px;
        color: #ccc;
        cursor: pointer;
        display: inline-block;
        padding: 10px;

        &:hover {
          color: #aaa;
        }
      }
    }

    .bc-modal-body {
      font-size: 14px;
      padding: 20px;
      overflow: hidden;
    }
  }
}
