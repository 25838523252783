.pagination {
  display: flex;
  justify-content: center;
  .page {
    cursor: pointer;
    height: 25px;
    width: 25px;
    text-align: center;
    border: 1px solid #b5b5b5;
    border-radius: 5px;
    margin-right: 5px;

    &.selected {
      background: #9e721d;
      color: white;
    }
  }
}
