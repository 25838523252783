.hover-parent:hover {
  .show-on-any-parent-hover {
    display: unset !important;
  }
}

.hover-cursor-pointer:hover, .link-like:hover {
  cursor: pointer;
}

.link-like:hover {
  text-decoration: underline;
}

.hover-overflow-scroll:hover {
  overflow: scroll !important;
}

.hover-overflow-auto:hover {
  overflow: auto !important;
}

.hover-underline:hover{
  text-decoration: underline !important;
}
