@charset "UTF-8";
/* Shrinking the sidebar from 250px to 80px and center aligining its content*/
#sidebar.active {
  min-width: 80px;
  max-width: 80px;
  text-align: center;
  transition: 100ms; }

#sidebar {
  min-width: 250px;
  max-width: 250px;
  min-height: calc(100vh - 68px);
  /* Le 68px est expliqué dans baselaout.scss */
  background: #fffff8;
  border-right: 1px solid #e8e8e8;
  transition: 100ms; }

#sidebar #sidebar-toggler {
  color: #9e721d;
  display: inline-block;
  width: 100%;
  height: 20px;
  line-height: 20px; }

#sidebar ul {
  padding-left: 0;
  padding-top: 20px; }

.active-hidden {
  white-space: nowrap; }

#sidebar.active .active-hidden {
  display: none; }

#sidebar.active ul {
  padding-left: 0;
  padding-right: 0; }

/* Toggling the sidebar header content, hide the big heading [h3] and showing the small heading [strong] and vice versa*/
#sidebar .sidebar-header strong {
  display: none; }

#sidebar.active .sidebar-header h3 {
  display: none; }

#sidebar.active .sidebar-header strong {
  display: block; }

#sidebar ul li a {
  text-align: left;
  color: #9e721d;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  width: 100%; }

#sidebar.active ul li a {
  justify-content: center;
  transition: 100ms; }

#sidebar ul li:hover {
  background: #f9f0e3; }

#sidebar ul li a:hover {
  text-decoration: none; }

#sidebar img {
  width: 24px;
  height: 24px;
  margin-right: 5px; }

#sidebar.active ul li a {
  padding: 20px 10px;
  text-align: center;
  font-size: 0.85em; }

#sidebar.active ul li a i {
  margin-right: 0;
  display: block;
  font-size: 1.8em;
  margin-bottom: 5px; }

/* Same dropdown links padding*/
#sidebar.active ul ul a {
  padding: 10px !important; }

/* Changing the arrow position to bottom center position,
   translateX(50%) works with right: 50%
   to accurately  center the arrow */
#sidebar.active .dropdown-toggle::after {
  top: auto;
  bottom: 10px;
  right: 50%;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%); }

@media (max-width: 768px) {
  /* 80px and its content aligned to centre. Pushing it off the screen with the
     negative left margin
  */
  #sidebar.active {
    min-width: 80px;
    max-width: 80px;
    text-align: center;
    margin-left: -80px !important; }
  /* Reappearing the sidebar on toggle button click */
  #sidebar {
    margin-left: 0; }
  /* Toggling the sidebar header content,
     hide the big heading [h3] and showing the small heading [strong] and vice versa
  */
  #sidebar .sidebar-header strong {
    display: none; }
  #sidebar.active .sidebar-header h3 {
    display: none; }
  #sidebar.active .sidebar-header strong {
    display: block; }
  /* Downsize the navigation links font size */
  #sidebar.active ul li a {
    padding: 20px 10px;
    font-size: 0.85em; }
  #sidebar.active ul li a i {
    margin-right: 0;
    display: block;
    font-size: 1.8em;
    margin-bottom: 5px; }
  /* Adjust the dropdown links padding*/
  #sidebar.active ul ul a {
    padding: 10px !important; }
  /* Changing the arrow position to bottom center position,
    translateX(50%) works with right: 50%
    to accurately  center the arrow */
  .dropdown-toggle::after {
    top: auto;
    bottom: 10px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%); } }
