$main-color: #9e721d;
$left-bar-size: 280px;
body {
  font-family: 'Open Sans', sans-serif;

  a {
    color: $main-color;
    &:hover, &:focus {
      color: $main-color;
    }
  }

  button, button.btn {
    color: white;
    background: $main-color;
    border: 1px solid $main-color !important;
    padding: 5px 20px;
    border-radius: 3px;
    &:hover {
      color: $main-color;
      background: rgba(208, 164, 79, 0.05);
      transition: 50ms;
    }
  }

  .auth-container {
    .logo-container {
      img {
        max-width: 100px;
      }

      margin-bottom: 35px;
    }
  }

  .BaseLayout {
    &.logged {
      width: 100%;
      .navbar-brand {
        padding-left: 20px;
      }

      .navbar-collapse {
        float: right;
        margin-top: 5px;
      }

      .container {
        padding: 20px 25px;
        width: 100%;
        max-width: none;
        height: 100%;
        overflow-x: hidden;
      }

    }

  }

  .admin-card {
    background: rgb(250, 250, 250);
    border-radius: 15px;
    padding: 40px;

    .information {
      margin-bottom: 5px;
      label {
        font-weight: bold;
      }
      span {
        margin-left: 10px;
      }

    }
  }

  .rc-time-picker-panel {
    z-index: 15000 !important;
  }

}
